<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-custom gutter-b">
              <div class="card-header border-0">
                <div class="card-title">
                  <h3>Job No.: # {{ $route.params.id }}</h3>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <b-form-group
                    id="input-group-1"
                    label="Reference no."
                    label-for="input-1"
                    description="For custom referencing if needed"
                    class="col-md-3"
                  >
                    <b-form-input
                      id="input-1"
                      type="text"
                      v-model="job.reference_no"
                      name="reference_no"
                    >
                    </b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card card-custom gutter-b">
          <div class="card-header border-0">
            <div class="card-title"><h3>Trips</h3></div>

            <div class="card-toolbar" v-if="false">
              <b-button size="lg" variant="white" class="mb-2" @click="addTrip">
                <b-icon icon="plus-circle" aria-label="Add"></b-icon>
              </b-button>
            </div>
          </div>
          <div class="card-body">
            <div
              class="row align-items-center border-bottom"
              v-for="(trip, index) in job.trips"
              :key="trip._id"
              :trip="trip"
              :config="config"
              :index="index"
            >
              <b-form-group
                id="input-group-1"
                label="Type"
                label-for="input-1"
                class="col-md-1 col-sm-6"
              >
                <b-form-select
                  id="input-1"
                  :options="tripType"
                  v-model="trip.type"
                  :disabled="config.disabletypechange"
                ></b-form-select>
              </b-form-group>

              <div class="col-md-2">
                <div class="row">
                  <b-form-group
                    id="input-group-2"
                    label="Company"
                    label-for="input-2"
                    class="col-sm-6 col-md-12"
                  >
                    <b-form-input
                      id="input-2"
                      type="text"
                      v-model="trip.company"
                    ></b-form-input>
                  </b-form-group>

                  <validation-provider
                    rules="required"
                    name="Date"
                    v-slot="{ valid, errors }"
                    class="col-sm-6 col-md-12"
                  >
                    <b-form-group
                      id="input-group-3"
                      label="Date"
                      label-for="input-3"
                    >
                      <b-form-input
                        id="input-1"
                        type="date"
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="trip.date"
                      ></b-form-input>
                      <b-form-invalid-feedback id="inputLiveFeedback">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>

              <div class="col-md-1">
                <div class="row">
                  <b-form-group
                    id="input-group-4"
                    label="From"
                    label-for="input-4"
                    class="col-sm-6 col-md-12"
                  >
                    <b-form-input
                      id="input-4"
                      type="time"
                      v-model="trip.from_time"
                      :disabled="config.disabletimechange"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-5"
                    label="Till"
                    label-for="input-5"
                    class="col-sm-6 col-md-12"
                  >
                    <b-form-input
                      id="input-5"
                      type="time"
                      v-model="trip.to_time"
                      :disabled="config.disabletimechange"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <b-form-group label="Address" class="col-md-2">
                <b-form-textarea
                  id="input-6"
                  size="sm"
                  v-model="trip.address"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-7"
                label="Unit no"
                label-for="input-7"
                class="col-md-1 col-sm-2"
              >
                <b-form-input
                  id="input-7"
                  type="text"
                  v-model="trip.unit_no"
                ></b-form-input>
              </b-form-group>

              <div class="col-md-1 col-sm-3">
                <div class="row">
                  <b-form-group
                    id="input-group-8"
                    label="Contact Person"
                    label-for="input-8"
                    class="col-sm-6 col-md-12"
                  >
                    <b-form-input
                      id="input-8"
                      type="text"
                      v-model="trip.contact_person"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-9"
                    label="Contact Number"
                    label-for="input-9"
                    class="col-sm-6 col-md-12"
                  >
                    <b-form-input
                      id="input-9"
                      type="text"
                      v-model="trip.contact_number"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <b-form-group label="Remarks" class="col-md-2">
                <b-form-textarea
                  id="input-10"
                  size="sm"
                  v-model="trip.remark"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="card card-custom gutter-b">
          <div class="card-header border-0">
            <div class="card-title">
              <h3>Items</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <validation-provider
                rules="required"
                name="Item Weight"
                v-slot="{ valid, errors }"
                class="col-md-4"
              >
                <b-form-group
                  id="input-group-21"
                  label="Weight(kg)"
                  label-for="input-21"
                  description="Please enter weight in Kilograms"
                >
                  <b-form-input
                    id="input-21"
                    type="text"
                    v-model="job.item_weight"
                    name="item_weight"
                    :state="errors[0] ? false : valid ? true : null"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputLiveFeedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                rules="required"
                name="Value"
                v-slot="{ valid, errors }"
                class="col-md-4"
              >
                <b-form-group
                  id="input-group-22"
                  label="Number of item"
                  label-for="input-22"
                  description="Please enter the amount in packaged form (Document / Bag / Carton)"
                >
                  <b-form-input
                    id="input-21"
                    type="text"
                    v-model="job.number_of_items"
                    name="number_of_items"
                    :state="errors[0] ? false : valid ? true : null"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputLiveFeedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
        </div>
        <submit-button :loading="loading" text="Submit" />
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import SubmitButton from "@/components/button/SubmitButton";

import { updateJob, getUpdateJobDetail } from "@/api/client-operation.api";
// import { getAllClientCompany } from "@/api/client.api";

import { useNotify } from "@/utils/notify";
import { getOmsStatus } from "@/utils/oms-status";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

class Item {
  constructor(name, qty, length, width, height, remark) {
    this._id = Math.random().toString(16).slice(2);
    this.name = name;
    this.qty = qty;
    this.length = length;
    this.width = width;
    this.height = height;
    this.remark = remark;
  }
}

export default {
  name: "updateClientjob",
  components: {
    // AddItemForm,
    ValidationProvider,
    ValidationObserver,
    SubmitButton,
  },
  setup(_, context) {
    const { notify } = useNotify(context);
    const { allJobStatus, allTripStatus } = getOmsStatus();

    const config = {
      disabletypechange: true,
      disabletimechange: true,
    };

    const job = reactive({
      id: 0,
      client: "",
      reference_no: "",
      itemWeight: "",
      numberOfItems: "",
      status: "",
      trips: [],
      items: [],
    });

    const addItem = function () {
      job.items.push(new Item("", 1, 0, 0, 0, ""));
    };

    function removeItem(index) {
      job.items.splice(index, 1);
    }

    const onSubmit = async () => {
      loading.value = true;
      try {
        await updateJob(job.id, job);
        notify("Job Created", `A job with the number ${job.id} is updated`);
        context.root.$router.push({ name: "ListClientJob" });
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      context.root.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      getUpdateJobDetail(context.root.$route.params.id).then((response) => {
        const {
          id,
          client,
          reference_no,
          item_weight,
          number_of_items,
          status,
          items,
          trips,
        } = response.data;

        job.items = items;
        job.trips = trips;
        job.id = id;
        job.client = client;
        job.reference_no = reference_no;
        job.item_weight = item_weight;
        job.number_of_items = number_of_items;
        job.status = status;
      });

      setTimeout(() => {
        // Remove page loader after some time
        context.root.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    const loading = ref(false);

    const tripType = [
      {
        text: "PICKUP",
        value: "PICKUP",
      },
      {
        text: "DELIVERY",
        value: "DELIVERY",
      },
    ];

    return {
      job,
      addItem,
      onSubmit,
      removeItem,
      config,

      loading,
      allJobStatus,

      tripType,
      tripStatus: allTripStatus,
    };
  },
};
</script>
